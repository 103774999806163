<template>
    <div id="bodydiv">
        <v-container fluid grid-list-xl pt-0 pb-0 px-3 class="full-height">
            <v-overlay
                :value="loading"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    :col-classes="'xl12 lg12 md12 sm12 xs12' + (ZohoDashboards.length === 1 ? ' pt-0' : '')"
                >
                    <v-tabs
                        v-model="dashboardCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                        v-if="ZohoDashboards.length > 1"
                    >
                        <v-tab
                            :key="board.ZohoDashboard.tab_title"
                            :ref="board.ZohoDashboard.tab_title.replace(' ','-').toLowerCase()"
                            ripple
                            v-for="board in ZohoDashboards"
                        >
                            <template v-if="board.ZohoDashboard.tab_title === 'AR Report'">
                                <AccountReceivablesReportTab :tab-title="board.ZohoDashboard.tab_title"/>
                            </template>
                            <template v-else-if="board.ZohoDashboard.tab_title === 'Sales Incentives'">
                                <span v-if="User.role === 'ROLE_SALES'">{{ $t('message.mySalesIncentives') }}</span>
                                <span v-else>{{ $t('message.salesIncentives') }}</span>
                            </template>
                            <template v-else>
                                {{ board.ZohoDashboard.tab_title }}
                            </template>
                        </v-tab>
                        <v-tab-item
                            v-for="(board, index) in ZohoDashboards"
                            :key="index"
                        >
                            <v-card flat>
                                <div>
                                    <template v-if="board.ZohoDashboard.tab_title === 'Sales Incentives'">
                                        <MyCommission @mounted="reportMounted"/>
                                    </template>
                                    <template v-else-if="board.ZohoDashboard.tab_title === 'OSD/ETS'">
                                        <EtsDueListing @mounted="reportMounted"/>
                                    </template>
                                    <template v-else-if="board.ZohoDashboard.tab_title === 'AR Report'">
                                        <AccountsReceivableList @mounted="reportMounted"/>
                                    </template>
                                    <template v-else-if="board.ZohoDashboard.tab_title === 'WS Credit'">
                                        <WareHouseSalesCredit @mounted="reportMounted"/>
                                    </template>
                                    <template v-else-if="board.ZohoDashboard.tab_title === 'APPIC/OTS'">
                                        <AppicBuyerAccessLog @mounted="reportMounted"/>
                                    </template>
                                    <template v-else>
                                        <vue-friendly-iframe :src="board.ZohoDashboard.src" @iframe-load="onIframeLoad()" v-if="index === 0" :key="index" :style="dynamicStyle" class="iframe-height"></vue-friendly-iframe>
                                        <vue-friendly-iframe :src="board.ZohoDashboard.src" v-else :key="index" :style="dynamicStyle" class="iframe-height"></vue-friendly-iframe>
                                    </template>
                                </div>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                    <v-card flat v-if="ZohoDashboards.length === 1">
                        <div>
                            <h5 class="mt-2">{{ $t('message.dashboard') }}</h5>
                            <vue-friendly-iframe :src="ZohoDashboards[0].ZohoDashboard.src" @iframe-load="onIframeLoad()" :style="dynamicStyle" class="iframe-height"></vue-friendly-iframe>
                        </div>
                    </v-card>
                    <v-card flat v-if="ZohoDashboards.length === 0">
                        <h2>{{ $t('message.noDashboardsAssigned') }}</h2>
                    </v-card>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import { api } from "../../../api";
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    // import {log} from "Helpers/helpers";
    const EtsDueListing = () => import("Components/Appic/EtsDueListing");
    import AccountsReceivableList from "Components/Appic/AccountsReceivableList";
    import AccountReceivablesReportTab from "Components/Appic/Dashboard/AccountReceivablesReportTab";
    import AppicBuyerAccessLog from "Components/Appic/Dashboard/AppicBuyerAccessLog.vue";
	import MyCommission from "Components/Appic/Dashboard/MyCommission.vue";
    import WareHouseSalesCredit from "Components/Appic/Dashboard/WareHouseSalesCredit.vue";

    export default {
        name: "Dashboard",
        components: {
			MyCommission,
            AppicBuyerAccessLog,
            AccountReceivablesReportTab,
            AccountsReceivableList,
            EtsDueListing,
            WareHouseSalesCredit
        },
        title: '',
        data() {
            return {
                loader: true,
                active: null,
                loading: false,
            }
        },
        computed: {
            ...mapFields('runtime',{
				dashboardCurrentTab: 'dashboardCurrentTab'
            }),
            ...mapGetters('user',{
                User: 'User',
				ZohoDashboards : 'ZohoDashboards'
            }),
            dynamicStyle () {
                return {
                    '--spacer-height': this.ZohoDashboards.length > 1 ? '120px' : '110px'
                }
            }
        },
        methods: {
            onIframeLoad() {
                this.loading = false
            },
            ...mapActions('buyer', {
                getAllActiveBuyers: 'getAllActiveBuyers',
                resetAllBuyers: 'resetAllBuyers',
            }),
            ...mapActions('supplier', {
                getAllSuppliers: 'getAllSuppliers',
                resetAllSuppliers: 'resetAllSuppliers',
            }),
            ...mapActions('document',{
                resetAllDocuments: 'resetAllDocuments',
            }),
            ...mapActions('paymentterm', {
                getAllBuyerContractPaymentTerms: 'getAllBuyerContractPaymentTerms'
            }),
            reportMounted() {
                this.loading = false
            }
        },
        created() {
            if(this.ZohoDashboards.length === 0) this.loading = false
        },
        mounted() {
            this.$nextTick(() => {
                const report = this.ZohoDashboards.find(db => db.ZohoDashboard.tab_title === 'AR Report')
                if(report && this.$route.query.tab === 'ar_report'){
                    const tab = this.$refs['ar-report'][0].$el
                    tab.click()
                }
            })
        }
    }
</script>

<style>
    .full-height {
        height: calc(100vh - 77px);
    }
    .iframe-height iframe {
        border: 0;
        height: calc(100vh - var(--spacer-height));
    }
    .v-tab {
        text-transform: none !important;
    }
</style>